//bank colors
$trb-blue: #003C69;
$trb-gold: #94785B;
$trb-alt-blue: #327EBC;

$trb-blue-hover: #d8efff;
$trb-gold-hover: #f0ddca;

$unselected-grey-background: #d8efff;

$primary-color: #063b6c;
$primary-menu-color: #002b5c;
$secondary-menu-color: #003366;
$primary-btn-color: #074787;
// $secondary-color: #ffae57;
$secondary-color: #8b7054;
$active-color: #EC7211;
$pink: #E56691;
$trb-mint: #40C9A2;
$blue: #3573D2;
$white: white;
$submit: #347FC4;
$light-blue: #B4CDED;
$abandon: #DE3C4B;
$cancel: #FFC107;
$completed: #17A2B8;
$light-mint: #B4EDD2;
$grey: #BDC7D0;
$text-box-blue: #003c92;
$text-box-focused: #337ab7;
$header: rgba(32, 31, 31, 0.977);
$save-green: #007504;
$cancel-red: #750000;
$excel-green: #107c41;
$pdf-red: #c50606;
$primary-color-light: #26547F;



//Service Desk Status 
$service-status-completed: #86c142;
$service-status-in-progress: #25669f;
$service-status-pending: #8a7153;
$service-status-declined: #c14242;