/* poppins-100 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("/fonts/poppins-v20-latin-100.woff2") format("woff2"), url("/fonts/poppins-v20-latin-100.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-200 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("/fonts/poppins-v20-latin-200.woff2") format("woff2"), url("/fonts/poppins-v20-latin-200.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-300 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("/fonts/poppins-v20-latin-300.woff2") format("woff2"), url("/fonts/poppins-v20-latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/fonts/poppins-v20-latin-regular.woff2") format("woff2"), url("/fonts/poppins-v20-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("/fonts/poppins-v20-latin-500.woff2") format("woff2"), url("/fonts/poppins-v20-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("/fonts/poppins-v20-latin-600.woff2") format("woff2"), url("/fonts/poppins-v20-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("/fonts/poppins-v20-latin-700.woff2") format("woff2"), url("/fonts/poppins-v20-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-800 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("/fonts/poppins-v20-latin-800.woff2") format("woff2"), url("/fonts/poppins-v20-latin-800.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-900 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("/fonts/poppins-v20-latin-900.woff2") format("woff2"), url("/fonts/poppins-v20-latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body.b-initially-hidden {
  visibility: hidden;
}
body.b-change-size #container {
  transition: all 0.3s;
}
body.b-screenshot * {
  transition: none !important;
  animation-duration: 0s !important;
}
body.b-size-phone {
  display: flex;
  /* background: url(images/woodsmall.jpg) no-repeat center center fixed; */
  background-size: cover;
}
body.b-size-phone #container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex: 0 0 375px;
  max-width: 375px;
  height: 727px;
  max-height: 727px;
  min-height: 727px;
  overflow: visible;
}
body.b-size-phone #container #fullscreen-button,
body.b-size-phone #container #trial-button,
body.b-size-phone #container .learnButton {
  display: none;
}
body.b-size-phone #container::after {
  content: "";
  position: absolute;
  width: auto;
  height: auto;
  max-height: calc(100% + 60px);
  top: -30px;
  right: -10px;
  bottom: -30px;
  left: -10px;
  border: 10px solid black;
  border-bottom-width: 30px;
  border-top-width: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* <trial-only> */
@media (max-width: 800px) {
  #trial-button {
    display: none;
  }
}
/* <trial-only> */
.b-grid.b-outer:not(.b-panel-collapsible) {
  flex: 1;
}

#container {
  position: relative;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transform: translate(0, 0);
  overflow: hidden;
}
#container::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  max-height: 0;
  left: 0;
  border: none;
  box-shadow: none;
  pointer-events: none;
}

.demo-header {
  display: flex;
  color: #fff;
  padding: 0.4em;
  padding-inline-start: 0.7em;
  transition: background-color 0.5s, padding 0.5s, font-size 0.1s;
  flex: 0 0 auto;
}
.demo-header a {
  color: inherit;
  text-decoration: none;
}
.demo-header #title-container {
  display: inline-flex;
  align-items: stretch;
  flex: 1;
  overflow: hidden;
}
.demo-header #title {
  display: inline-flex;
  align-items: center;
  padding-inline-start: 2.1em;
  font-size: 1.3em;
  /* background: url(images/bryntum-symbol-white.svg) no-repeat; */
  background-size: 1.6em;
  background-position-y: center;
  white-space: nowrap;
}
.demo-header #title h1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
}
body[style*=rtl] .demo-header #title {
  background-position-x: right;
}
.demo-header #tools {
  display: flex;
  align-items: center;
  background-color: inherit;
  transition: background-color 0s, margin-top 0.3s;
}
.demo-header #tools > .b-widget:not(.b-tool) {
  margin-inline-end: 0.5em;
}
.demo-header #tools > .b-button,
.demo-header #tools > .b-widget label {
  color: #fff;
}
.demo-header #tools .b-tool {
  color: #fff;
  font-size: 1.1em;
  width: 2.5em;
  height: 2.5em;
}
.demo-header .b-button.b-tool:hover, .demo-header .b-button.b-tool.b-pressed:hover, .b-using-keyboard .demo-header .b-button.b-tool:focus,
.demo-header .b-button.b-transparent:not(.b-tab):hover,
.demo-header .b-button.b-transparent:not(.b-tab).b-pressed:hover,
.b-using-keyboard .demo-header .b-button.b-transparent:not(.b-tab):focus {
  background: rgba(255, 255, 255, 0.15);
  opacity: 1;
}
.demo-header .b-button.b-tool.b-pressed, .demo-header .b-button.b-tool:focus,
.demo-header .b-button.b-transparent:not(.b-tab).b-pressed,
.demo-header .b-button.b-transparent:not(.b-tab):focus {
  background: none;
}
.demo-header .b-button.b-tool:active, .demo-header .b-button.b-tool.b-pressed:active,
.demo-header .b-button.b-transparent:not(.b-tab):active,
.demo-header .b-button.b-transparent:not(.b-tab).b-pressed:active {
  background: rgba(255, 255, 255, 0.4);
  opacity: 1;
}
.demo-header #header-tools {
  display: inline-block;
}

.b-hint code, .b-tooltip code {
  background: #eee;
  padding: 2px 4px;
  line-height: 1;
}
.b-theme-classic .b-hint code, .b-theme-material .b-hint code, .b-theme-classic .b-tooltip code, .b-theme-material .b-tooltip code {
  background: #444;
}
.b-theme-classic-dark .b-hint code, .b-theme-classic-dark .b-tooltip code {
  background: #111;
}

.b-hint .header {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.b-notransition * {
  transition: none;
}

@media (max-width: 450px) {
  .rc-feedbackbutton-iframe,
.b-hint,
#fullscreen-button,
#trial-button,
.learnButton,
.b-codeeditor .b-icon-file-download {
    display: none;
  }

  .demo-header a#title {
    padding-inline-start: 0;
    background: none;
  }
}
@media (max-width: 568px) {
  .demo-header #title {
    margin-inline-start: 0.5em;
  }
}
#header-tools {
  display: none;
}

.info-popup.b-popup .b-popup-content {
  padding: 1.5em;
}
.info-popup.b-popup .b-popup-content .b-html {
  flex-direction: column;
}
.info-popup.b-popup .b-popup-content .header {
  font-weight: 700;
}
.info-popup.b-popup .b-popup-content .description {
  padding-top: 0.5em;
  line-height: 1.4em;
}
.info-popup.b-popup .b-popup-content .b-widget:not(.b-html):not(.b-fieldtrigger) {
  margin-top: 1em;
  margin-bottom: 0;
}
.info-popup.b-popup .b-popup-content .b-button {
  justify-content: center;
  margin-inline-end: 1em;
}
.info-popup.b-popup .b-popup-content .example-description {
  margin-bottom: 1em;
}
.b-theme-material .info-popup.b-popup .b-popup-content > .b-combo:not(.b-html):not(.b-fieldtrigger) {
  margin-top: 2em;
}

.b-widget.b-codeeditor {
  width: 450px;
  flex: unset;
}
.b-widget.b-codeeditor:not(.b-collapsed) {
  border-inline-start: 5px solid #d8d9da;
}
.b-widget.b-codeeditor.b-collapsed .b-panel-collapse-revealer {
  display: none;
}
.b-widget.b-codeeditor:not(.b-resizing) {
  transition: border-width 0.1s;
}
.b-widget.b-codeeditor .b-toolbar {
  flex: 0 0 auto;
}
.b-widget.b-codeeditor .b-codeeditor-body-wrap, .b-widget.b-codeeditor .b-panel-overlay {
  overflow: hidden;
}
.b-widget.b-codeeditor .b-panel-content {
  direction: ltr;
  background-color: #333435;
  padding: 0;
  overflow: auto;
}
.b-widget.b-codeeditor .b-panel-content code:focus {
  outline: none;
}
.b-widget.b-codeeditor .b-panel-content pre {
  color: #fff;
  padding: 1em;
  margin: 0;
}
.b-widget.b-codeeditor .comment,
.b-widget.b-codeeditor .comment .keyword,
.b-widget.b-codeeditor .comment .string {
  color: #90a4ae;
}
.b-widget.b-codeeditor .keyword {
  color: #ffb74d;
}
.b-widget.b-codeeditor .curly {
  color: #ffe0b2;
}
.b-widget.b-codeeditor .string,
.b-widget.b-codeeditor .string .keyword {
  color: #66bb6a;
}
.b-widget.b-codeeditor .tag {
  color: #a5d6a7;
}
.b-widget.b-codeeditor.b-resizing * {
  user-select: none;
}
.b-widget.b-codeeditor.b-over-resize-handle {
  cursor: ew-resize;
}
.b-widget.b-codeeditor .b-header-title {
  white-space: nowrap;
}
.b-widget.b-codeeditor .b-header-title i {
  margin-inline-end: 0.5em;
}
.b-widget.b-codeeditor.b-hidden {
  display: flex !important;
  border-inline-start-width: 0;
}
.b-widget.b-codeeditor .b-bottom-toolbar {
  background-color: #ffb74d;
  transition: color 0.2s, background-color 0.2s;
}
.readonly .b-widget.b-codeeditor .b-bottom-toolbar {
  background-color: #ff8d46;
}
.b-widget.b-codeeditor.invalid .b-codeeditor-header i {
  color: #b71c1c;
  background: #fff;
}
.b-widget.b-codeeditor.invalid .b-bottom-toolbar {
  color: #fff;
  background: #b71c1c;
}

.feedback-savedmsg h3 {
  margin-top: 0.6em;
}
.feedback-savedmsg i {
  margin-inline-end: 0.2em;
}

.version-update-toast {
  max-width: 250px;
  line-height: 1.4em;
}
.version-update-toast h4 {
  position: relative;
  margin-top: 0;
  margin-bottom: 0.5em;
}
.version-update-toast h4 i.b-fa-times {
  position: absolute;
  right: 0;
  top: 0;
}
.b-rtl .version-update-toast h4 i.b-fa-times {
  right: auto;
  left: 0;
}
.version-update-toast a {
  text-decoration: underline;
}

.b-demo-unavailable .b-panel-header i.b-fa {
  margin-inline-end: 0.5em;
}

.b-theme-stockholm .demo-header,
.b-theme-classic .demo-header,
.b-theme-material .demo-header {
  background-color: #0076f8;
}

body.b-theme-classic-dark {
  background-color: #222;
}

.b-theme-classic-dark .demo-header {
  background-color: #152365;
}

.b-theme-classic-light .demo-header {
  background-color: #3191f7;
}

.b-screenshot .demo-header,
.b-hide-header .demo-header {
  display: none;
}

.b-hide-toolbar .b-outer > .b-panel-body-wrap > .b-top-toolbar,
.b-hide-toolbar .b-outer.b-panel > header.b-panel-ui-toolbar {
  display: none;
}

.docsmenu a {
  text-decoration: none;
}
.docsmenu .b-menuitem {
  padding: 1.2em 1em;
}
.docsmenu .b-docs-menu-header {
  font-size: 1.2em;
  padding: 0.7em 2em;
  text-transform: uppercase;
}
.docsmenu .b-docs-menu-header .b-menu-text {
  margin-inline-start: 0;
}

.b-docs-category {
  font-weight: 600;
  background: #eee;
}
.b-theme-classic-dark .b-docs-category {
  background: #66666644;
}

.b-codeeditor .b-bottom-toolbar .b-toolbar-content {
  padding: 1em !important;
}

.b-scheduler .name {
  display: flex;
}
.b-scheduler .name img {
  width: 38px;
  border-radius: 100%;
  margin: 0 15px 0 5px;
}
.b-scheduler .name dl {
  flex: 1;
  margin: 0;
  padding: 0;
}
.b-scheduler .name dt {
  font-weight: bold;
  color: #444;
}
.b-scheduler .name dd {
  font-size: 0.8em;
  color: #999;
  margin: 4px 0;
}

.b-theme-classic-dark .name dt {
  color: #eee;
}

.b-grid-row.b-selected {
  background-color: #3183fe59;
}

.b-button.b-green:not(.b-tab) {
  color: white;
  border-color: #002b5c;
  background-color: #074787;
}
.b-button.b-green:not(.b-tab):hover:not(.b-disabled):not(.b-pressed) {
  border-color: #002b5c;
  color: white;
}
.b-button.b-green:not(.b-tab):hover:not(.b-disabled) {
  background-color: #074787;
  color: white;
}
.b-button.b-green:not(.b-tab):active:not(.b-disabled), .b-button.b-green:not(.b-tab):active:focus:not(.b-disabled) {
  background-color: #074787;
  color: #fff;
}
