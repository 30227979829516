.b-gantt > .b-toolbar {
  border-bottom: 1px solid;
}
.b-gantt > .b-toolbar > .b-content-element > .b-widget:not(.b-last-visible-child) {
  margin-inline-end: 1em;
}
.b-gantt > .b-toolbar > .b-content-element .b-button {
  min-width: 3.5em;
}

.filter-by-name label {
  display: none;
}

.b-theme-stockholm .b-gantt > .b-toolbar {
  border-bottom-color: #d8d9da;
}

.b-theme-classic .b-gantt > .b-toolbar {
  background-color: #f1f1f4;
  border-bottom-color: #b0b0b6;
}
.b-theme-classic .b-gantt > .b-toolbar .b-has-label label {
  color: #555;
}

.b-theme-classic-light .b-gantt > .b-toolbar {
  background-color: #fff;
  border-bottom-color: #e0e0e0;
}

.b-theme-material .b-gantt > .b-toolbar {
  background-color: #fff;
  border-bottom: none;
}
.b-theme-material .b-gantt > .b-toolbar .filter-by-name label {
  display: block;
}

.b-theme-classic-dark .b-gantt > .b-toolbar {
  background-color: #2b2b2f;
  border-bottom-color: #2a2b2e;
}

.b-status-column-cell {
  font-size: 0.8em;
}

.b-status-column-cell i::before {
  margin-inline-end: 0.5em;
}

.Started {
  color: #bbb;
}

.Late {
  color: #ff5722;
}

.Completed {
  color: #34d844;
}

.settings-menu .b-menu-content {
  padding: 0.5em;
}
.settings-menu .b-slider {
  width: 12em;
  margin-bottom: 0.5em;
}
